import React from 'react'
import styled from 'styled-components'
import { FaInfoCircle } from 'react-icons/fa';


const StatsCard = styled.div`
  height: 100%;
  overflow: hidden;
  border-radius: 12px;
  background-color: ${props => props.theme.dashboard.cardBackground};
  ${({containerStyles}) => containerStyles ? containerStyles : ''}
`

const StatTitleContainer = styled.div`
  background-color: ${props => props.theme.dashboard.cardHeaderBackground};
  padding: 10px 0;
  text-align: center;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
`

const StatTitle = styled.h2`
  margin-bottom: 0;
  color: ${props => props.theme.dashboard.cardTitle};
  font-size: 16px;
  font-weight: 500;
`

const InfoIcon = styled(FaInfoCircle)`
  cursor: pointer;
  color: ${props => props.theme.colors.light2};
`;

const StatCard = ({ title, children, containerStyles, faqComponent, tooltipText }) => {

  // Log the received tooltipText prop
  console.log(`StatCard Title: ${title}, tooltipText:`, tooltipText);

  // Explicitly check if tooltipText is truthy
  const shouldShowIcon = !!tooltipText;
  console.log(`StatCard Title: ${title}, shouldShowIcon:`, shouldShowIcon);

  return (
    <StatsCard containerStyles={containerStyles}>
      <StatTitleContainer>
        <StatTitle>
          {title}
          {faqComponent}
        </StatTitle>
        {/* Use the explicit boolean for conditional rendering */}
        {shouldShowIcon && (
          <InfoIcon 
            size={14} 
            title={tooltipText} 
            // Keep yellow for now until we confirm visibility
          />
        )}
      </StatTitleContainer>
      {children}
    </StatsCard>
  )
}

export default StatCard
