import React, { useState, memo } from 'react'
import styled from 'styled-components'
import { Cell, PieChart, Pie, ResponsiveContainer, Tooltip } from 'recharts';
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import numeral from 'numeral';
import _ from 'lodash'

import StatsCard from './StatsCard'
import { capitalize } from 'utils'
import MotivationalMessage from './MotivationalMessage'
import { PieLabelArray } from './PieLabelArray'
import { pieChartColors, renderCustomPieChartLabel, renderCustomPieChartLabelLine, removeSmallData } from '../util'

const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex: 1;
  height: calc(100% - 40px);
  padding: 0 0 10px 0;
`

const DropdownContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 8px;
`

const containerStyles = `
  grid-row-start: 3;
  grid-row-end: span 2;
  grid-column-start: 3;

  @media (max-width: 1250px) {
    grid-row-start: 5;
    grid-row-end: span 2;
    grid-column-start: 1;
  }

  @media (max-width: 700px) {
    grid-row-start: 7;
    grid-row-end: span 2;
    grid-column-start: 1;
  }
`

const formatTooltipLabel = (value, name, props) => [numeral(value).format('$0,0[.]00'), name]

export const SalesByLocationType = memo(({ stats, tooltipText }) => {
  let data = []
  if (stats) {
    data = Object.keys(stats).map(key => {
      const value = stats[key] || 0
      return {name: key, value}
    })
    data = removeSmallData(data)
  }
  console.log('rendering: SalesByLocationType')
  return (
    <StatsCard
      title={'Sales by Location Type'}
      containerStyles={containerStyles}
      tooltipText={tooltipText}
    >
      <StatsContainer>
        {data?.length ? (
          <>
            <div style={{height: '20px'}} />
            <ResponsiveContainer height="80%">
              <PieChart
                key={Math.random()}
              >
                <Pie
                  dataKey="value"
                  data={data}
                  cx="50%"
                  cy="50%"
                  innerRadius={5}
                  outerRadius={80}
                  // fill="#8884d8"
                  label={renderCustomPieChartLabel}
                  labelLine={renderCustomPieChartLabelLine}
                  paddingAngle={0}
                >
                  {data.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={pieChartColors[index % pieChartColors.length]} stroke={pieChartColors[index % pieChartColors.length]} strokeWidth={1}/>
                  ))}
                </Pie>
                <Tooltip formatter={formatTooltipLabel} />
              </PieChart>
            </ResponsiveContainer>
            <PieLabelArray data={data} shortenTo={4} elementId={'sales-by-location-type-labels'} />
          </>
        ) : (
          <MotivationalMessage message={`Cue "Sounds of Silence"`} />
        )}
      </StatsContainer>
    </StatsCard>
  )
}, (prevProps, nextProps) => {
  return _.isEqual(prevProps.stats, nextProps.stats) && prevProps.tooltipText === nextProps.tooltipText;
})