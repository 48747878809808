import React, { useState } from 'react'
import styled from 'styled-components'
import StatsCard from './StatsCard'
import numeral from 'numeral'
import MotivationalMessage from './MotivationalMessage'

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';


import { Bar as ChartBar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  height: calc(100% - 40px);
  padding: 8px 10px;
  overflow: auto;
`

const StatRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 4px;
`

const Bar = styled.div`
  background-color: #0088FE;
  height: 24px;
  ${({ width }) => (width ? 'width: ' + width * 75 + '%;' : '')}
  border-radius: 5px;
  margin: 0 16px 0 0;
`

const LabelColumn = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: flex-start;
  width: 70px;
`

const EmployeeName = styled.p`
  color: #fff;
  padding: 0;
  margin: 0;
  font-size: 10px;
  text-align: center;
`

const EmployeeSales = styled.p`
  color: #fff;
  padding: 0;
  margin: 0;
  font-size: 10px;
  text-align: center;
  font-weight: 700;
`

export const SalesLeaderboard = ({ stats, tooltipText }) => {
  let data = []
  if (stats) {
    data = Object.values(stats).sort((a, b) => b.sales - a.sales)
  }

  let names
  let sales

  if (stats) {
    names = Object.values(stats).map(data => data.name)
    sales = Object.values(stats).map(data => data.sales)
  }

  const options = {
    indexAxis: 'y',
    plugins: {
      legend: {
        display: false,
        labels: {
          color: '#FFFFFF'
        }
      },
      title: {
        display: false,
        // text: percentCompleted,
        font: {
          size: 25,

        },
        color: '#FFFFFF'
      },
      tooltip: {
        callbacks: {
          title: () => '',
          label: function (context) {
            return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.x);
          }
        }
      }
    },
    scales: {
      x: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          drawTicks: false,
          drawBorder: false,
          drawOnChartArea: false
        },
        font: {
          size: 15
        }
      },

      y: {
        ticks: {
          // display: false
          color: '#FFFFFF'
        },
        grid: {
          display: false,
          // drawTicks: false,
          drawBorder: false,
          drawOnChartArea: false
        },
      }
    }
  };

  const dataChart = {
    labels: names,
    datasets: [{
      axis: 'y',
      data: sales,
      fill: false,
      backgroundColor: ['#198754', '#0088FE', '#dc3545'],
      barThickness: 25
    }],
  }

  return (
    <StatsCard
      title={'Sales Leaderboard'}
      tooltipText={tooltipText}
    // style={{ gridRowStart: '2', gridRowEnd: 'span 2' }}
    >
      <StatsContainer>
        {data.length > 0
          ? <ChartBar options={options} data={dataChart} />
          : <MotivationalMessage message="Let's get some names on this board!" />
        }
        {/* {data.length > 0 ? data.map((item, index) => {
          return (
            <StatRow key={index}>
              <LabelColumn>
                <EmployeeName>{item.name}</EmployeeName>
                <EmployeeSales>{numeral(item.sales).format('$0,0')}</EmployeeSales>
              </LabelColumn>
              <Bar width={index ? item.sales / data[0].sales : 1} />
            </StatRow>

          )
        }) : <MotivationalMessage message="Let's get some names on this board!" />} */}
      </StatsContainer>
    </StatsCard>
  )
}
