import React from 'react';
import styled from 'styled-components';
import StatsCard from './StatsCard'; // Assuming StatsCard is the common card wrapper

const StatsContainer = styled.div`
  display: flex;
  justify-content: center; // Center the metric by default
  align-items: center;
  height: calc(100% - 40px); // Assuming 40px is the header height from StatsCard
`;

const Metric = styled.p`
  text-align: center;
  color: ${props => props.theme.dashboard.metric};
  margin: 4px 0; // Adjusted margin
  font-size: 40px;
`;

const Prefix = styled.span`
  font-size: 24px; // Smaller font size for prefix/suffix
  color: ${props => props.theme.dashboard.metric};
  margin-right: 2px; // Spacing
`;

const Suffix = styled.span`
  font-size: 24px; // Smaller font size for prefix/suffix
  color: ${props => props.theme.dashboard.metric};
  margin-left: 2px; // Spacing
`;

export const SimpleMetricCard = ({ title, value, prefix, suffix, formatter, tooltipText }) => {
  const displayValue = formatter ? formatter(value) : (value || 0);

  return (
    <StatsCard title={title} tooltipText={tooltipText}>
      <StatsContainer>
        <Metric>
          {prefix && <Prefix>{prefix}</Prefix>}
          {displayValue}
          {suffix && <Suffix>{suffix}</Suffix>}
        </Metric>
      </StatsContainer>
    </StatsCard>
  );
}; 