import React, { Component } from 'react'
import styled from 'styled-components'
import StatsCard from './StatsCard'


import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';


import { Bar as ChartBar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

const StatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  flex: 1;
`

const Stat = styled.div`
  height: 76px;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
`

const MetricTitle = styled.p`
  color: ${props => props.theme.dashboard.metric};
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  margin-top: 12px;
`

const Metric = styled.span`
  text-align: center;
  color: ${props => props.theme.dashboard.metric};
  margin-left: 4px;
  font-size: 14px;
  font-weight: 700;
`

const ChartRow = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const BarBackground = styled.div`
  background-color: #fff;
  height: 18px;
  width: 80%;
  border-radius: 0 15px 15px 0;
`

const BarFill = styled.div`
  position: relative;
  top: 0;
  left: 0;
  background-color: #ab2e3c;
  width: ${({ width }) => width};
  height: 100%;
  border-radius: 0 15px 15px 0;
`

const ChartLabel = styled.span`
  color: #fff;
  margin-left: 14px;
  font-size: 14px;
  font-weight: 700;
`

export const InspectionMetrics = ({ stats, tooltipText }) => {
  const renderStats = () => {
    return (
      <>
        {stats &&
          Object.keys(stats).map(key => (
            <Stat key={`${key}`}>
              <MetricTitle>
                {camelCaseToLabel(key)}:<Metric>{stats[key]}</Metric>
              </MetricTitle>
            </Stat>
          ))}
      </>
    )
  }

  const percentCompleted = `${Math.round(
    ((stats?.completed || 0) / stats?.created) * 100
  ) || 0}%`

  const camelCaseToLabel = value => {
    let splitted = value.split(/(?=[A-Z])/)
    splitted[0] = splitted[0].charAt(0).toUpperCase() + splitted[0].substring(1)
    return splitted.join(' ')
  }

  const maxChart = stats?.created
  const minChart = 0

  const options = {
    indexAxis: 'y',
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: false,
        labels: {
          color: '#FFFFFF'
        }
      },
      title: {
        display: false,
        // text: percentCompleted,
        font: {
          size: 25,

        },
        color: '#FFFFFF'
      },
      tooltip: {
        // callbacks: {
        //   label: function (context) {
        //     return new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(context.parsed.x);
        //   }
        // }
      }
    },
    scales: {
      x: {
        ticks: {
          display: false,
        },
        grid: {
          display: false,
          drawTicks: false,
          drawBorder: false,
          drawOnChartArea: false
        },
        font: {
          size: 15
        },
        min: minChart,
        max: maxChart
      },

      y: {
        ticks: {
          display: false,
          color: '#FFFFFF'
        },
        grid: {
          display: false,
          // drawTicks: false,
          drawBorder: false,
          drawOnChartArea: false
        },
      }
    }
  };

  const dataChart = {
    labels: ['Inspection Completed'],
    datasets: [{
      axis: 'y',
      data: [stats?.completed || 0],
      // fill: false,
      // backgroundColor: ['#198754', '#0088FE', '#dc3545'],
      backgroundColor: ['#0088FE'],
      barThickness: 25
    }],
  }

  return (
    <StatsCard title={'Inspection Metrics'} tooltipText={tooltipText}>
      <StatsContainer>{renderStats()}</StatsContainer>
      <ChartRow>
        <div style={{ height: 50, width: '70%' }}>
          <ChartBar options={options} data={dataChart} />
        </div>

        {/* <BarBackground>
          <BarFill width={percentCompleted} />
        </BarBackground> */}
        <ChartLabel>{percentCompleted}</ChartLabel>
      </ChartRow>
    </StatsCard>
  )
}
