import React, { Component } from 'react'
import styled from 'styled-components'
import StatsCard from './StatsCard'

const StatsContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex: 1;
`

const Stat = styled.div`
  height: 76px;
  flex: 1;
`

const MetricTitle = styled.h3`
  color: ${props => props.theme.dashboard.metric};
  font-size: 14px;
  font-weight: 300;
  text-align: center;
  margin-top: 0;
`

const Metric = styled.p`
  text-align: center;
  color: ${props => props.theme.dashboard.metric};
  margin-top: 16px;
  margin-bottom: 0;
  font-size: 40px;
`

export const Tally = ({stats, title, tooltipText}) => {

  const renderStats = () => {
    return (
      <>
        {stats &&
        Object.keys(stats).map(key => (
          <Stat key={`${key}`}>
            <Metric>{stats[key]}</Metric>
            <MetricTitle>{camelCaseToLabel(key)}</MetricTitle>
          </Stat>
        ))}
      </>
    )
  }

  const camelCaseToLabel = value => {
    let splitted = value.split(/(?=[A-Z])/)
    splitted[0] = splitted[0].charAt(0).toUpperCase() + splitted[0].substring(1)
    return splitted.join(' ')
  }

  return (
    <StatsCard title={title} tooltipText={tooltipText}>
      <StatsContainer>{renderStats()}</StatsContainer>
    </StatsCard>
  )
}
