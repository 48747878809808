import React from 'react'
import styled from 'styled-components'
import StatsCard from './StatsCard'

const StatsContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: calc(100% - 40px);
`

const Metric = styled.p`
  text-align: center;
  color: ${props => props.theme.dashboard.metric};
  margin: 0;
  font-size: 40px;
  padding: 0;
`

const SmallSpan = styled.span`
  font-size: 24px;
  color: ${props => props.theme.dashboard.metric};
`

const CountText = styled.p`
  text-align: center;
  color: ${props => props.theme.dashboard.metric};
  margin: 0;
  padding: 0;
  font-size: 12px;
`

export const AverageContractValue = ({ average, count, tooltipText }) => {
  const countString = count > 1 ? `${count} Total Contracts` : count === 1 ? `${count} Contract` : 'No Contracts'
  
  return (
    <StatsCard title={'Average Contract Value'} tooltipText={tooltipText}>
      <StatsContainer>
          <Metric><SmallSpan>$</SmallSpan>{average || 0}</Metric>
          <CountText>{countString}</CountText>
      </StatsContainer>
    </StatsCard>
  )
}
