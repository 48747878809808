import React from 'react';
import styled from 'styled-components';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css' // Import skeleton styles

// Use similar structure to StatsCard for consistency
const CardContainer = styled.div`
  height: 100%;
  overflow: hidden;
  border-radius: 12px;
  background-color: ${props => props.theme.dashboard.cardBackground};
`;

const TitleContainer = styled.div`
  background-color: ${props => props.theme.dashboard.cardHeaderBackground};
  padding: 10px 0;
  text-align: center;
  height: 40px; // Match StatsCard header height
  margin-bottom: 10px; // Add some space before content skeleton
`;

const ContentContainer = styled.div`
  padding: 0 15px; // Add some padding for the content skeleton
  height: calc(100% - 50px); // Adjust height considering title margin
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const SkeletonCard = () => {
  // Use the dashboard's theme colors for the skeleton if possible
  // These colors are examples; adjust based on your actual theme
  const baseColor = "#3a3a3a"; // Darker background for skeleton waves
  const highlightColor = "#555555"; // Lighter color for the wave effect

  return (
    <SkeletonTheme baseColor={baseColor} highlightColor={highlightColor}>
      <CardContainer>
        <TitleContainer>
          <Skeleton height={20} width="60%" />
        </TitleContainer>
        <ContentContainer>
          {/* Simple placeholder for content area */}
          <Skeleton height={40} width="40%" />
          <Skeleton height={15} width="80%" style={{ marginTop: '10px' }}/>
        </ContentContainer>
      </CardContainer>
    </SkeletonTheme>
  );
}; 